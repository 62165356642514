/* Variables */
:root {
  --color-one: #fe5c2b;
  --color-two: #ff8fe9;
  --color-three: #584cff;
  --color-four: #fff031;
  --color-dark: #070069;
  --color-light: #ebeaff;
  --base-size: 16px;
  --base-padding: 32px;
  --font-heading: 'MADE Dillan';
  --font-body: 'Apfel Grotezk';
}

/* Selection */
::selection {
  background-color: var(--color-four);
  color: var(--color-one);
}

body {
  font-family: var(--font-body), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: var(--base-size);
  line-height: 1.2;
  background-color: var(--color-one);
}

body.menu-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: var(--base-size);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.header {
  position: relative;
  height: 140px;
}

.submit--button {
  display: block;
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 120px;
  height: 48px;
  font-size: 1.25em;
  color: var(--color-one);
  background-color: var(--color-four);
  border-radius: 1em;
  box-shadow: 0px 13px 10px -10px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 46px;
  text-decoration: none;
  animation: btnWiggle 5s infinite cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

@keyframes btnWiggle {
  0% {
    -webkit-transform: rotate(0deg);
  }

  2% {
    -webkit-transform: rotate(-6deg);
  }

  5% {
    -webkit-transform: rotate(6deg);
  }

  10% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

.footer--note {
  text-align: center;
  padding: 2em 1em;
  color: var(--color-dark);
}

.logo {
  position: absolute;
  top: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.logo:hover {
  cursor: pointer;
}

.categories {
  margin: 2em 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.filter--item:hover {
  cursor: pointer;
}

.filter--item {
  display: flex;
  padding: 0.5em 0.5em 0.5em 1.5em;
  margin-bottom: 0.5em;
  border: 2px solid var(--color-two);
  background-color: var(--color-one);
  color: var(--color-four);
  border-radius: 1em;
  margin-right: 0.5em;
}

.filter--item.is-active {
  border: 2px solid var(--color-four);
  background-color: var(--color-four);
  color: var(--color-one);
}

.filter--item.is-active span:after {
  content: '×';
  width: 1em;
}

.filter--item span {
  white-space: nowrap;
}

.filter--item span:after {
  display: inline-block;
  content: '';
  text-align: center;
  line-height: 1em;
  width: 1em;
}


.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: space-around;
  padding: 1em;
}

/* First: default */
.card {
  flex: 1;
  min-width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--color-three);
  text-align: center;
  padding: 1em;
  margin-bottom: 3%;
  border-radius: calc(2 * var(--base-size));
  color: var(--color-two);
  transform: rotate(-2deg);
  transition: 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card--foreground {
  transform: rotate(2deg);
  transition: 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:hover {
  transform: rotate(2deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:hover .card--foreground {
  transform: rotate(-2deg) scale(0.95);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

/* Second */
.card:nth-child(3n-1) {
  /*pink*/
  background-color: var(--color-two);
  transform: rotate(2deg);
}

.card:nth-child(3n-1) .card--foreground {
  transform: rotate(-2deg);
  transition: 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n-1):hover {
  transform: rotate(-2deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n-1):hover .card--foreground {
  transform: rotate(2deg) scale(0.95);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n-1) .tag {
  border: 1px solid var(--color-three);
  color: var(--color-three);
}

/* Third */
.card:nth-child(3n) {
  /*yellow*/
  background-color: var(--color-four);
  transform: rotate(-1deg);
}

.card:nth-child(3n) .card--foreground {
  transform: rotate(1deg);
  transition: 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n):hover {
  transform: rotate(1deg) scale(1.1);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n):hover .card--foreground {
  transform: rotate(-1deg) scale(0.95);
  transition: transform 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.card:nth-child(3n) .tag {
  border: 1px solid var(--color-three);
  color: var(--color-three);
}

.body--title {
  font-size: 2.5rem;
  font-family: var(--font-heading), Cambria, 'Hoefler Text', Utopia, 'Liberation Serif',
    'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
  line-height: 1;
  letter-spacing: 0.025rem;
  padding: 0.25em 0 0.5em 0;
  margin: 0;
  color: var(--color-four);
}

.card:nth-child(3n) .body--title {
  color: var(--color-one);
}

.card a {
  color: var(--color-two);
}

.card:nth-child(3n-1) a,
.card:nth-child(3n) a {
  color: var(--color-dark);
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tag {
  display: inline;
  border: 1px solid var(--color-two);
  color: var(--color-two);
  padding: 0.15em 0.5em;
  margin-right: 0.25em;
  font-size: 0.85rem;
}

/* Navigation */
.nav--toggle {
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 30;
}

.burger {
  display: none;
}

.burger--toggle {
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  cursor: pointer;
  padding: 8px 4px;
}

.burger--icon {
  position: relative;
  width: 24px;
  height: 16px;
  display: block;
}

.burger--icon:before,
.burger--icon:after {
  border-top: 4px solid var(--color-four);
  content: '';
  position: absolute;
  left: 0px;
  right: 0;
}

.burger--icon:before {
  top: 0px;
}

.burger--icon:after {
  bottom: 0px;
}

/* Burger animation config */
.burger--icon,
.burger--icon:before,
.burger--icon:after {
  transition-property: transform, border-color;
  transition-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.burger--icon {
  transition-delay: 0.125s;
}

.burger--icon:before,
.burger--icon:after {
  transition-delay: 0s;
  transform-origin: center;
}

/* Burger animation after checked */
.burger:checked+.burger--toggle .burger--icon {
  transition-delay: 0s;
}

.burgerchecked+.burger--toggle .burger--icon:before,
.burger:checked+.burger--toggle .burger--icon:after {
  transition-delay: 0.025s;
}

.burger:checked+.burger--toggle .burger--icon:before {
  transform: translateY(4px) rotate(135deg);
  border-color: var(--color-two);
}

.burger:checked+.burger--toggle .burger--icon {
  border-color: transparent;
}

.burger:checked+.burger--toggle .burger--icon:after {
  transform: translateY(-8px) rotate(-135deg);
  border-color: var(--color-two);
}

.nav--overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 99.9vh;
  padding-bottom: 2em;
  overflow: scroll;
  background: var(--color-three);
  z-index: 20;
}

.nav--overlay__visible {
  display: block;
}

.nav--overlay__hidden {
  display: none;
}

.menu {
  max-width: 800px;
  margin: 0 auto;
  color: var(--color-four);
  font-size: 1.4rem;
  padding: 4em 1.5em;
}

.menu p {
  margin-bottom: 2em;
  max-width: 720px;
}

.menu--title {
  font-size: 2.75rem;
  font-family: var(--font-heading), Cambria, 'Hoefler Text', Utopia, 'Liberation Serif',
    'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
  line-height: 1;
  letter-spacing: 0.025rem;
  padding: 0.25em 0;
  margin: 0;
  color: var(--color-four);
}

.menu a {
  color: var(--color-four);
  border-bottom: 2px solid var(--color-two);
}

@media (min-width: 940px) {
  .logo {
    /* position: fixed; */
  }

  .cards {
    flex-direction: row;
  }

  .card {
    margin-left: 1em;
    margin-bottom: 2em;
  }

  .menu {
    font-size: 2.5em;
    line-height: 1.3;
  }

  .submit--button {
    position: absolute;
    top: 1em;
    left: 1em;
  }
}

@media (min-width: 1280px) {
  .cards {
    max-width: 1200px;
    margin: 0 auto;
  }

  .submit--button {
    position: absolute;
    top: 1em;
    left: 1em;
  }
}
